import React from "react";
import { useSelector } from "react-redux";
import rss_icon from "../../../Assets/images/rss-icon.png";
import "../Picture/picture.css";
import SearchField from "../../../components/editor/SearchField";
function RssComponent({ onLoadRssFromDb }) {
  const { content, isContentLoading } = useSelector((state) => state.Content);
  return (
    <div className="picture-component-container">
      {/* <SearchField /> */}
      <div className="upslash-container">
        {content.map((con, index) => {
          if (con.type == "rss") {
            return (
              <div
                className="upslash-divs"
                onClick={() => onLoadRssFromDb(con.url)}
              >
                <img src={rss_icon} />
                <span>{con?.name?.substring(0, 20)}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default RssComponent;
