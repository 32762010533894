import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Loader from "../../../components/loader/Loader";
import {
  CalendarMonthOutlined,
  Check,
  Close,
  Delete,
  DeleteOutlineOutlined,
  DesktopWindowsOutlined,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  closeCalender,
  licenseDateUpdate,
  openCalender,
  screenFilters,
} from "../../../Redux/Actions/screenAction";
import toast from "react-hot-toast";
import { useCallback, useState } from "react";
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: "#15192C",
  fontSize: "14px",
  fontWeight: "700",
}));
function ScreenTable({
  screenLoading,
  filtered,
  editScreenHandler,
  deleteScreenHandler,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  onGetSyncList,
  role,
  onLicenseActivate,
  onSelectLicense,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  // const [tillDate,setTillDate]=useState(new Date(screen.licenseTill).toISOString().split("T")[0]);
  const [id, setId] = useState("");
  const onGetDate = (e, id) => {
    // setTillDate(e.target.value);
    setDate(e.target.value);
    setId(id);
  };

  const onUpdateDate = useCallback(() => {
    dispatch(licenseDateUpdate(id, date));
  }, [id, date]);
  return (
    <>
      <div className="screen-table">
        <TableContainer
          component={Paper}
          sx={{ margin: "5px auto", borderRadius: "16px" }}
        >
          {role != "superadmin" && (
            <div
              style={{
                height: "100%",
                width: "100%",
                // border: "1px solid black",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
                margin: "0 10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  minWidth: "5%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="status-badge success"></div>
                <span>=Online</span>
              </div>
              <div
                style={{
                  display: "flex",
                  minWidth: "5%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="status-badge danger"></div>
                <span>=Offline</span>
              </div>
              <div
                style={{
                  display: "flex",
                  minWidth: "5%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="status-badge license-expires"></div>
                <span>=License Expired</span>
              </div>
              <div
                style={{
                  display: "flex",
                  minWidth: "5%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="status-badge license-waiting"></div>
                <span>=Waiting for licensed</span>
              </div>
            </div>
          )}
          <Table>
            <TableHead>
              {role == "superadmin" && (
                <TableRow>
                  <CustomTableCell colSpan={13} align={"left"}>
                    <select
                      onChange={(e) =>
                        e.target.value == ""
                          ? toast.error("Select any state.")
                          : dispatch(screenFilters(e.target.value))
                      }
                      id={"state"}
                      style={{ width: "20vw", height: "3vh" }}
                    >
                      <option value="">--select--</option>
                      <option value="all">All</option>
                      <option value="offline">
                        <span>Offline</span>
                      </option>
                      <option value="online"> Online</option>
                      <option value="pending"> Wait for license</option>
                      <option value="expires">License expires</option>
                    </select>
                  </CustomTableCell>
                </TableRow>
              )}
              <TableRow
                style={{ borderBottom: "2px solid var(--primary-color)" }}
              >
                <CustomTableCell align="center"></CustomTableCell>
                <CustomTableCell align="center">ID</CustomTableCell>
                <CustomTableCell align="center">Partner</CustomTableCell>
                <CustomTableCell align="center">Company</CustomTableCell>
                <CustomTableCell align="center">Folder</CustomTableCell>
                <CustomTableCell align="center">Screen Name</CustomTableCell>
                <CustomTableCell align="center">Screen Code</CustomTableCell>
                <CustomTableCell align="center">License</CustomTableCell>
                <CustomTableCell align="center">Playlist</CustomTableCell>
                <CustomTableCell align="center">Created</CustomTableCell>
                <CustomTableCell align="center">Expires</CustomTableCell>
                {/* <CustomTableCell align="center">Status</CustomTableCell> */}
                <CustomTableCell align="center">Heartbeat</CustomTableCell>
                <CustomTableCell align="center">Actions</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {screenLoading ? (
                <Loader />
              ) : (
                filtered.length >= 0 &&
                filtered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((screen, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell align="left">
                          <input
                            type={"checkbox"}
                            style={{ height: "20px", width: "20px" }}
                            value={screen.mac}
                            onChange={onGetSyncList}
                          />
                        </TableCell>
                        <TableCell align="center">{++index}</TableCell>
                        <TableCell align="center">
                          {screen?.user?.parentId?.username}
                        </TableCell>
                        <TableCell align="center">
                          {screen?.user?.companyName}
                        </TableCell>
                        <TableCell align="center">
                          {screen?.folderId?.name}
                        </TableCell>
                        <TableCell align="center">{screen.name}</TableCell>
                        <TableCell align="center">{screen.code}</TableCell>
                        <TableCell align="center">
                          {screen.license == 1
                            ? `${screen.license} year`
                            : `${screen.license} years`}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ display: "flex", gap: "2px" }}
                        >
                          {role == "superadmin"
                            ? screen?._playlist?.map((p) => (
                                <div
                                  style={{
                                    height: "20px",
                                    minWidth: "70px",
                                    padding: "1px",
                                    borderRadius: "10px",
                                    border: "1px solid black",
                                    background: "rgba(255,255,255,.5)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {p?.playlist?.name?.substring(0, 10)}
                                </div>
                              ))
                            : screen?._playlist?.map((p) => (
                                <div
                            style={{
                                    height: "20px",
                                    width: "70px",
                                    padding: "1px",
                                    borderRadius: "10px",
                                    border: "1px solid black",
                                    background: "rgba(255,255,255,.5)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {p?.playlist?.name?.substring(0, 10)}
                                </div>
                              ))}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(screen.licenseStarted).toDateString()}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(screen.licenseTill).toDateString()}
                          {role == "superadmin" &&
                            (screen.isCalenderOpened ? (
                              <div style={{ display: "flex", gap: "2px" }}>
                                <input
                                  type="date"
                                  // value={
                                  //   tillDate
                                  // }
                                  onChange={(e) => onGetDate(e, screen._id)}
                                />
                                <Check
                                  onClick={onUpdateDate}
                                  fontSize={"small"}
                                  htmlColor="green"
                                  style={{ cursor: "pointer" }}
                                />
                                <Close
                                  onClick={() =>
                                    dispatch(closeCalender(screen._id))
                                  }
                                  fontSize={"small"}
                                  htmlColor="red"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            ) : (
                              <CalendarMonthOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  dispatch(openCalender(screen._id))
                                }
                              />
                            ))}
                        </TableCell>
                        <TableCell align="center">
                          {screen.isLicensed ? (
                            Date.now() > new Date(screen.licenseTill) ? (
                              <>
                                <div className="status-badge license-expires"></div>
                              </>
                            ) : screen.onlinesStatus ? (
                              <>
                                <div className="status-badge success"></div>
                              </>
                            ) : (
                              <>
                                <div className="status-badge danger"></div>
                              </>
                            )
                          ) : screen.isLicensed == false ? (
                            <>
                              <div className="status-badge license-waiting"></div>
                            </>
                          ) : (
                            <>
                              <div className="status-badge license-expires"></div>
                            </>
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {screen.isLicensed ? (
                            role == "superadmin" &&
                            Date.now() > new Date(screen.licenseTill) ? (
                              <button
                                style={{
                                  height: "30px",
                                  width: "100px",
                                  border: "1px solid black",
                                  borderRadius: "20px",
                                  background: "black",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  onSelectLicense(screen._id, true)
                                }
                              >
                                License
                              </button>
                            ) : (
                              <>
                                <ModeEditOutlineOutlined
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "-2px",
                                  }}
                                  className="main-color"
                                  onClick={() =>
                                    editScreenHandler(screen._id, screen.mac)
                                  }
                                />
                                <DeleteOutlineOutlined
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "-2px",
                                  }}
                                  className="main-color"
                                  onClick={() =>
                                    deleteScreenHandler(screen._id, screen.mac)
                                  }
                                />

                                <DesktopWindowsOutlined
                                  className="main-color"
                                  height={20}
                                  width={20}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/setting/${screen._id}`)
                                  }
                                />
                              </>
                            )
                          ) : screen.isLicensed == false &&
                            Date.now() < new Date(screen.licenseTill) &&
                            role == "superadmin" ? (
                            <button
                              style={{
                                height: "25px",
                                width: "70px",
                                border: "1px solid black",
                                borderRadius: "20px",
                                background: "orange",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                onLicenseActivate(screen._id, screen.mac)
                              }
                            >
                              Activate
                            </button>
                          ) : (
                            <>N/A</>
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            style={{ display: "flex", justifyContent: "space-between" }}
            count={filtered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </TableContainer>
      </div>
    </>
  );
}

export default ScreenTable;
