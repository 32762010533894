import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {fabric} from 'fabric';
import "./design.css";
import { shallowEqual, useSelector } from "react-redux";
import { Layers } from "lucide-react";
const Design = ({ onGetImage, layer,onLoadTemplateFromDb ,_folder}) => {
  const [value, setValue] = React.useState("1");
  const {content}=useSelector(state=>({
   content: state.Content.content.filter(t=>t.type=='template' && t?.folderId?._id==_folder)
  }),shallowEqual);
// console.log(content);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    // dir.click();
  };

  
  // console.log(layer)
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Layers"
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Templete"
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="layer-container">
              {layer.map((l, index) => {
                return (
                  <>
                    <div className="layers">{<img src={l?.toDataURL()} />}</div>
                  </>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className='main-template-container'>

            <input
              id={"file-get"}
              type={"file"}
              onChange={(e) => onGetImage(e.target.files[0])}
              hidden
            />
            <button style={{
          height: "30px",
          width: "150px",
          border: "1px solid gray",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }} onClick={openDirectory}>Select File</button>

<div className="template-container">
        {content.map((con, index) => {
          if (con.type == "template") {
            return (
              <div
                className="template-divs"
                onClick={() => onLoadTemplateFromDb(con?.canva,con.id,con.name)}
              >
                <Layers
                 style={{
                  height:'80%',
                  width:'100%',
                }}
                />
                {/* <img src={videoPlayerThumbnail} /> */}
                <span>{con?.name?.substring(0, 20)}</span>
              </div>
            );
          }
        })}
      
      </div>

            </div>
            
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
export default Design;
