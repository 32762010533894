import React, { useEffect, useState } from "react";
import "./editor.css";
import { Divider, Popover } from "@mui/material";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { Redo, RotateCcw, RotateCw, Save, Trash } from "lucide-react";
function CanvaProperty({ canvaProps, onSetLengthOfCanvas }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [canvaColor, setCanvaColor] = useState("white");
  // const {height,width}=useSelector(state=>state.Editor);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const open = Boolean(anchorEl);
  const colorOpen = Boolean(colorAncherEl);
  const id = open ? "simple-popover" : undefined;
  const colorId = colorOpen ? "simple-popover" : undefined;
  const [length, setLength] = useState({
    height: "",
    width: "",
  });
  useEffect(() => {
    setLength({
      height: canvaProps?.height,
      width: canvaProps?.width,
    });
    // if(canvaProps.canvaColor!==undefined) setCanvaColor(canvaProps.canvaColor);
  }, [canvaProps.height, canvaProps.width, canvaProps.canvaColor]);
  // const onGetLength = (e) => {
  //   const { name, value } = e.target;
  //   setLength((prev) => ({ ...prev, [name]: value }));
  // };
  const onChangeColorComplete = (color) => {
    setCanvaColor(color.hex);
    canvaProps.onGetChangeCanvaBackgroundColor(color.hex);
  };

  return (
    <div className="canvas-prop-container">
 <div className="seprator-vertical"></div>
      <div className='fields-prop'>
      <RotateCcw  onClick={canvaProps.onRedu}/>
      <RotateCw onClick={canvaProps.onUndo}/>
      </div>
      <div className="seprator-vertical"></div>
      {/* <div className="seprator-vertical"></div> */}
      <div className='fields-prop'>
      <button style={{height:'100%',width:'20px'}} onClick={canvaProps.onZoomOut}>-</button>
      <span>{Math.round(canvaProps.zoom * 100)}%</span>
      <button style={{height:'100%',width:'20px'}} onClick={canvaProps.onZoomIn}>+</button>
      </div>
      {/* <div className="seprator-vertical"></div> */}
      <div className="fields-prop">
        <button
          style={{
            background: canvaProps?.canvaProps?.backgroundColor,
            border: "1px solid black",
            height: "30px",
            width: "30px",
            borderRadius:50
          }}
          aria-describedby={colorId}
          onClick={handleColorClick}
        ></button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker
            color={canvaProps?.canvaProps?.backgroundColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>
      <div className="fields-prop">
        <input style={{width:'10vw',textAlign:'center',height:'40px',border:'1px solid gray',borderRadius:'20px'}} type="text" value={canvaProps?.templateName} onChange={(e)=>canvaProps?.onChange(e.target.value)} placeholder="Enter Name"/>
      </div>
      <div className="fields-prop">
        <button
          className="canva-size-button"
          aria-describedby={id}
          onClick={handleClick}
        >
          Canvas Size
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          //  className="popover_class"
        >
          <div className="pop-over">
            Width:
            <input
              name="width"
              value={canvaProps.width}
              onChange={canvaProps.onGetDimention}
              type={"number"}
            />
             Height:
            <input
              name="height"
              value={canvaProps.height}
              onChange={canvaProps.onGetDimention}
              type={"number"}
            />
            <button
              className="canva-confirm-size-button"
              onClick={() => onSetLengthOfCanvas(length)}
            >
              Confirm
            </button>
          </div>
        </Popover>
        {/* Height:<input name="height" value={canvasProps.height} onChange={onGetLength} type={'number'}/> */}
      </div>
      <Divider orientation="vertical" />
      <div className="fields-prop">
        <button className="canva-size-button" onClick={canvaProps.onExport}>Export</button>
      </div>
      <div className="fields-prop">
        {/* <div > */}
        <button style={{height:'40px',width:'40px',border:'none',outlet:'none',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',background:'lightgray'}} className="canva-clear-button" onClick={canvaProps.onClear}>
          <Trash/>
        </button>
        {/* </div> */}
      </div>
      <div className="fields-prop">
        <button className="canva-save-button" onClick={canvaProps?.isEdit ? canvaProps?.onEdit : canvaProps?.onSave}>
        <Save />
        {
          canvaProps?.isEdit ? <>Update</> : <>Save</>
        }
          </button>
      </div>
      {
        canvaProps?.isEdit && 
      <div className="fields-prop">
        <button className="canva-save-button" onClick={canvaProps.onStartNew}>
          New
          </button>
      </div>
      }
    </div>
  );
}

export default CanvaProperty;
