import { fabric } from "fabric";
import { v4 as uuid } from "uuid";
export default class EditorOps {
  constructor() {
    fabric.Object.prototype.toObject = (function (originalToObject) {
      return function () {
        // Extend the default object serialization with custom properties
        return fabric.util.object.extend(originalToObject.call(this), {
          id: this.id || "",
          name: this.name || "",
          eleType: this.eleType || "",
          text: this.text || "",
          url: this.url || "",
          fontSize: this.fontSize || "",
          contents: this.contents || [],
          weatherObj: this.weatherObj || {},
          scaletype: this.scaletype || {},
          tempObj: this.tempObj || {},
          zoom: this.zoom || "",
          scrollstrip: this.scrollstrip || {},
        });
      };
    })(fabric.Object.prototype.toObject);
  }
  createRect() {
    return new fabric.Rect({
      id: uuid(),
      top: 50,
      left: 50,
      shapeType: "rect",
      name: "Shape",
      // cornerColor: "black",
      // borderColor:'red',
      // backgroundColor:'green',
      width: 100,
      height: 100,
      fill: "gray",
      stroke: "black",
      strokeWidth: 1,
    });
  }
  createCircle() {
    return new fabric.Circle({
      id: uuid(),
      shapeType: "circle",
      name: "Shape",
      top: 50,
      left: 50,
      // cornerColor: "black",
      // borderColor: "red",
      width: 300,
      height: 300,
      radius: 50,
      stroke: "black",
      // strokeWidth: 2,
      fill: "gray",
    });
  }
  createLine() {
    return new fabric.Line([50, 50, 250, 50], {
      id: uuid(),
      shapeType: "line",
      name: "Shape",
      stroke: "black",
      strokeWidth: 2,
      // strokeDashArray: [1, 1]
    });
  }
  createPolygone() {
    return new fabric.Polygon(
      [
        {
          x: 150,
          y: 50,
        },
        {
          x: 225,
          y: 150,
        },
        {
          x: 150,
          y: 250,
        },
        {
          x: 75,
          y: 150,
        },
      ],
      {
        id: uuid(),
        name: "Shape",
        shapeType: "polygon",
        fill: "gray",
        stroke: "black",
        strokeWidth: 2,
      }
    );
  }
  createTriangle() {
    return new fabric.Triangle({
      id: uuid(),
      shapeType: "triangle",
      eleType: "triangle",
      name: "Shape",
      width: 100,
      height: 100,
      stroke: "black",
      strokeWidth: 2,
      fill: "transparent",
      left: 50,
      top: 50,
    });
  }
  addEmojiToCanvas(emoji) {
    return new fabric.Text(emoji, {
      id: uuid(),
      name: "Text",
      top: 50,
      left: 50,
      editable: true,
      // cornerColor: "black",
      // borderColor: "red",
      textAlign: "center",
      selectable: true,
    });
  }
  addTextToCanvas(text, type) {
    return new fabric.Text(text, {
      id: uuid(),
      eleType: type,
      name: "Text",
      top: 50,
      left: 50,
      editable: true,
      // cornerColor: "black",
      // borderColor: "red",
      textAlign: "center",
      selectable: true,
    });
  }
  addTextStripBox(text, fontSize = 30, obj) {
    // console.log(text,obj)
    return new fabric.Textbox(text, {
      id: uuid(),
      name: "Textbox",
      fontSize: fontSize,
      top: 50,
      left: 50,
      scrollstrip: obj,
      editable: false,
      eleType: "scrollstrip",
      textAlign: "center",
      selectable: true,
    });
  }
  addShadow() {
    return new fabric.Shadow({
      color: "black",
      blur: 5,
      offsetX: 10,
      offsetY: 10,
    });
  }
  addTextbox(text, fontSize) {
    return new fabric.Textbox(text, {
      id: uuid(),
      name: "Textbox",
      fontSize: fontSize,
      top: 50,
      left: 50,
      // right:70,
      // bottom:70,
      editable: true,
      eleType: "text",
      // cornerColor: "black",
      // borderColor: "black",
      textAlign: "center",
    });
  }

  addAnalogClock(url, type, a) {
    new fabric.Image.fromURL(
      url,
      (img) => {
        img.scale(0.3);
        img.set({
          id: uuid(),
          url: url,
          paintFirst: "fill",
          cornerColor: "black",
          eleType: type,
          lockScalingFlip: true,
          hasControls: true,
        });

        a(img);
      },
      { crossOrigin: "anonymous" }
    );
  }
  addImage(url, type, a) {
    new fabric.Image.fromURL(
      url,
      (img) => {
        img.scale(0.3);
        img.set({
          id: uuid(),
          url: url,
          scaletype: { fitXY: true, none: false },
          paintFirst: "fill",
          cornerColor: "black",
          eleType: type,
        });

        a(img);
      },
      { crossOrigin: "anonymous" }
    );
  }
  addLocalContent(imgsrc, url, type, cb) {
    new fabric.Image.fromURL(
      imgsrc,
      (img) => {
        img.scale(0.3);
        img.set({
          id: uuid(),
          url: url,
          scaletype: { fitXY: true, none: false },
          paintFirst: "fill",
          cornerColor: "black",
          eleType: type,
        });
        // console.log(img)

        cb(img);
      },
      { crossOrigin: "anonymous" }
    );
  }
  addWeather(imgsrc, obj, type, cb) {
    new fabric.Image.fromURL(
      imgsrc,
      (img) => {
        img.scale(1);
        img.set({
          id: uuid(),
          url: "",
          weatherObj: obj,
          paintFirst: "fill",
          cornerColor: "black",
          eleType: type,
        });
        // console.log(img)

        cb(img);
      },
      { crossOrigin: "anonymous" }
    );
  }

  addTemp(imgsrc, obj, type, cb) {
    new fabric.Image.fromURL(
      imgsrc,
      (img) => {
        img.scale(1);
        img.set({
          id: uuid(),
          url: "",
          tempObj: obj,
          paintFirst: "fill",
          cornerColor: "black",
          eleType: type,
        });
        // console.log(img)

        cb(img);
      },
      { crossOrigin: "anonymous" }
    );
  }

  addPlaylist(imgsrc, content, cb) {
    new fabric.Image.fromURL(
      imgsrc,
      (img) => {
        img.scale(0.3);
        img.set({
          id: uuid(),
          url: "",
          contents: content,
          scaletype: { fitXY: true, none: false },
          paintFirst: "fill",
          cornerColor: "black",
          eleType: "playlist",
        });
        // console.log(img)

        cb(img);
      },
      { crossOrigin: "anonymous" }
    );
  }
}
