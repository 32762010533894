import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyContent, emptyContent } from "../../Redux/Actions/formAction";
import { addContentInPlaylist } from "../../Redux/Actions/playlistAction";
import styles from "./tab.module.css";
import Image from "./tabviews/images/Image";
import Url from "./tabviews/url/Url";
import Video from "./tabviews/videos/Video";
import PdfTab from "./tabviews/pdf/PdfTab";
import Widget from "./tabviews/widget/Widget";
import TemplateTab from "./tabviews/template/TemplateTab";
const tabs = {
  image: Image,
  video: Video,
  url: Url,
  Pdf: PdfTab,
  widget: Widget,
  template: TemplateTab,
};
function Tab(props) {
  const dispatch = useDispatch();
  const { contentForm, isContentAvailable } = useSelector(
    (state) => state.Form
  );
  const { content } = useSelector((state) => state.Content);
  const [type, setType] = useState("image");
  const Component = tabs[type];
  useEffect(() => {
    isContentAvailable && dispatch(emptyContent());
  }, []);
  useEffect(() => {
    // socketRef.current.emit('leaving--connection')
    dispatch(copyContent(content));
  }, []);
  // const emitOfAddContent=(mac)=>{
  //     const id=setTimeout(() => {
  //         props.socketRef.current?.emit('content_added',{detail:{mac:mac}})
  //         clearTimeout(id);
  //     }, 2000);
  //   }
  const submitContent = () => {
    dispatch(addContentInPlaylist(contentForm));
    //    console.log(props.screenMac)
    //    if(props.screenMac!=="" || props.screenMac!==undefined){
    //    emitOfAddContent(props.screenMac);
    //    }
    props.close(false);
  };
  return (
    <>
      <div className={`${styles.tabContainer}`}>
        <div className={`${styles.tabHeader}`}>
          <div className={`${styles.tabs}`}>
            <button
              className={`${styles.tabBtn} ${
                type === "image" ? styles.tabActive : ""
              }`}
              onClick={() => setType("image")}
            >
              Image
            </button>
            <button
              className={`${styles.tabBtn} ${
                type === "video" ? styles.tabActive : ""
              }`}
              onClick={() => setType("video")}
            >
              Video
            </button>
            <button
              className={`${styles.tabBtn} ${
                type === "url" ? styles.tabActive : ""
              }`}
              onClick={() => setType("url")}
            >
              URL
            </button>
            {/* <button
              className={`${styles.tabBtn} ${
                type === "Pdf" ? styles.tabActive : ""
              }`}
              onClick={() => setType("Pdf")}
            >
              PDF
            </button> */}
            <button
              className={`${styles.tabBtn} ${
                type === "widget" ? styles.tabActive : ""
              }`}
              onClick={() => setType("widget")}
            >
              Widgets
            </button>
            <button
              className={`${styles.tabBtn} ${
                type === "template" ? styles.tabActive : ""
              }`}
              onClick={() => setType("template")}
            >
              Templates
            </button>
          </div>
        </div>
        <div className={`${styles.tabBody}`}>
          <div className={`${styles.innerBody}`}>
            <Component pId={props.pId} />
          </div>
        </div>
        <div className={`${styles.tabFooter}`}>
          <button className="btn-cancel" onClick={() => props.close(false)}>
            close
          </button>
          <button className="btn-save" onClick={submitContent}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default Tab;
