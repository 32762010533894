import React, { useEffect, useState } from "react";
import "./text.css";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import TitleIcon from "@mui/icons-material/Title";
import { Popover } from "@mui/material";
import { SketchPicker } from "react-color";
import SearchField from "../../../components/editor/SearchField";
function Text({ onSelectText, textProps, onAlignLeft, onAlignRight }) {
  const [textProperties, setTextProperties] = useState({
    textLeft: false,
    textCenter: false,
    textRight: false,
    textColor: "",
    textFontSize: "",
  });
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [shadowColorAncherEl, setShadowColorAncherEl] = useState(null);
  const [alignAncherEl, setAlignAncherEl] = useState(null);
  const [fontSizeAncherEl, setFontSizeAncherEl] = useState(null);
  const [fontTransparencyAncherEl, setFontTransparencyAncherEl] =
    useState(null);
  const [canvaColor, setCanvaColor] = useState("black");
  const [shadowCanvaColor, setShadowCanvaColor] = useState("black");
  const [length, setLength] = useState({
    x: 10,
    y: 10,
  });
  const colorOpen = Boolean(colorAncherEl);
  const shadowColorOpen = Boolean(shadowColorAncherEl);
  const alignOpen = Boolean(alignAncherEl);
  const fontSizeOpen = Boolean(fontSizeAncherEl);
  const fontTransparencyOpen = Boolean(fontTransparencyAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const shadowColorId = shadowColorOpen ? "simple-popover" : undefined;
  const alignId = alignOpen ? "simple-popover" : undefined;
  const fontSizeId = fontSizeOpen ? "simple-popover" : undefined;
  const fontTransparencyId = fontTransparencyOpen
    ? "simple-popover"
    : undefined;

  useEffect(() => {
    if (textProps) {
      setLength({
        x: textProps?.textBoxProps?.shadow?.offsetX,
        y: textProps?.textBoxProps?.shadow?.offsetY,
      });
    }
  }, [textProps]);
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleShadowColorClick = (event) => {
    setShadowColorAncherEl(event.currentTarget);
  };
  const handleShadowColorClose = () => {
    setShadowColorAncherEl(null);
  };
  const handleAlignClick = (event) => {
    setAlignAncherEl(event.currentTarget);
  };
  const handleAlignClose = () => {
    setAlignAncherEl(null);
  };
  const handleFontSizeClick = (event) => {
    setFontSizeAncherEl(event.currentTarget);
  };
  const handleFontSizeClose = () => {
    setFontSizeAncherEl(null);
  };
  const handleFontTransparencyClick = (event) => {
    setFontTransparencyAncherEl(event.currentTarget);
  };
  const handleFontTransparencyClose = () => {
    setFontTransparencyAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextFontColor(color.rgb,(properties) =>
      textProps.onSetAllProperties(properties));
  };
  const onChangeShadowColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextBoxShadowColor(color.rgb,(properties) =>
      textProps.onSetAllProperties(properties));
  };
  const onChangeOffsetValues = (e) => {
    setLength((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const _onChangeTextShadowState = (e) => {
    if (e.target.checked) {
      textProps.onChangeTextBoxShadowState(true, (properties) =>
        textProps.onSetAllProperties(properties)
      );
    } else {
      textProps.onChangeTextBoxShadowState(false,(properties) =>
        textProps.onSetAllProperties(properties));
    }
  };

  return (
    <div className="text-container">
      {/* <SearchField/> */}
      <button
        style={{
          height: "40px",
          width: "100%",
          background:'var(--primary-color)',
          color:'var(--primary-forecolor)',
          border: "1px solid gray",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
        onClick={()=>onSelectText('Enter Text',50)}
      >
        Add Text
      </button>
      <div className="tool--wrapper">
        <p>Default Text Style</p>
        <div className='text--tool'>
        <button
       className="buttons"
         
       onClick={()=>onSelectText('Add Heading',40)}
      >
        <span style={{ color:'#8C8A8A',
          fontWeight:'600',margin:'0 10px'}}>Add A Heading</span>
      </button>
        </div>       
      </div>
      <div className='tool--wrapper'>
      <div className='text--tool'>
        <button
        className="buttons"
        onClick={()=>onSelectText('Sub Heading',30)}
      >
        <span style={{ color:'#8C8A8A',fontSize:'14px',lineHeight:'21px',
          fontWeight:'500',margin:'0 10px'}}>Add Sub Heading</span>
      </button>
        </div>
      </div>
      <div className='tool--wrapper'>
      <div className='text--tool'>
        <button
       className='buttons'
       onClick={()=>onSelectText('Span tag',10)}
      >
        <span style={{ color:'#8C8A8A',fontSize:'10px',lineHeight:'21px',
          fontWeight:'400',margin:'0 10px'}}>Add A Little Of Body Text</span>
      </button>
        </div>
      </div>
      <div className="tool--wrapper">
      <p>Text Color:</p>
      <div className="text--tool">
        <button className="buttons" onClick={handleColorClick}
        >
          <div style={{height:'15px',width:'15px',border:'1px solid gray',borderRadius:'50%',background:`${textProps?.textBoxProps?.fill}`}}></div>
          <span>{textProps?.textBoxProps?.fill}</span>
          
        </button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={canvaColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>

      </div>
<div className="tool--wrapper">
        <p>Font Size:</p>
      <div className="text--tool">
        <input
          type={"range"}
          value={textProps?.textBoxProps?.fontSize}
          min={0}
          onChange={(e) => textProps.onChangeTextFontSize(e.target.value,(properties) =>
            textProps.onSetAllProperties(properties))}
        />
      </div>
</div>

    {/* <div className="tool--wrapper">
        <p>Shadow:</p>{" "}
      <div className="text--tool">
        <input
          type={"checkbox"}
          style={{ height: "20px", width: "20px" }}
          checked={textProps.textBoxProps.isShadow}
          onChange={_onChangeTextShadowState}
        />
      </div>
    </div> */}
      {textProps.textBoxProps.isShadow && (
        <>
        <div className='tool--wrapper'>
            <p>Shadow:</p>
          <div className="text--tool">
            <input
              type={"range"}
              value={textProps?.textBoxProps?.shadow?.size}
              min={0}
              onChange={(e) =>
                textProps.onChangeTextBoxShadowBlur(e.target.value,(properties) =>
                  textProps.onSetAllProperties(properties))
              }
            />
          </div>
        </div>
   <div className='tool--wrapper'>
            <p>shadow Color:</p>
          <div className="text--tool">
            <button
           className='buttons'
              // className="text-tool-btn-top"
              onClick={handleShadowColorClick}
            >
              <div style={{height:'15px',width:'15px',border:'1px solid gray',borderRadius:'50%',background:`${textProps?.textBoxProps?.shadow?.color}`}}></div>
              <span>{textProps?.textBoxProps?.shadow?.color}</span>
              {/* <TextFormatIcon
                style={{
                  color: `${textProps?.textBoxProps?.shadow?.color}`,
                }}
                fontSize="large"
              /> */}
            </button>
            <Popover
              id={shadowColorId}
              open={shadowColorOpen}
              anchorEl={shadowColorAncherEl}
              onClose={handleShadowColorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <SketchPicker
                color={shadowCanvaColor}
                onChangeComplete={onChangeShadowColorComplete}
              />
            </Popover>
          </div>
   </div>
<div className='tool--wrapper'>
            <p>offsetX:</p>
          <div className="text--tool">
            <input
              type={"number"}
              value={length.x}
              name={"x"}
              onChange={(e) => {
                onChangeOffsetValues(e);
                textProps.onGetOffsetVal({ x: e.target.value, y: length.y },(properties) =>
                  textProps.onSetAllProperties(properties));
              }}
              style={{ width:'100%' }}
            />
          </div>
</div>
<div className='tool--wrapper'>
            <p>offsetY:</p>
          <div className="text--tool">
            <input
              type={"number"}
              value={length.y}
              name={"y"}
              onChange={(e) => {
                onChangeOffsetValues(e);
                textProps.onGetOffsetVal({ y: e.target.value, x: length.x },(properties) =>
                  textProps.onSetAllProperties(properties));
              }}
              style={{ width:'100%' }}
            />
          </div>
</div>
        </>
      )}
    </div>
  );
}

export default Text;
