import React from "react";
import "../Text/text.css";
function ToolPlaylistOp({ playlist }) {
  const onChange = (e) => {
    const { value } = e.target;
    let obj = {};
    if (value == "fitXY") {
      obj.fitXY = true;
      obj.none = false;
    }
    if (value == "none") {
      obj.fitXY = false;
      obj.none = true;
    }
    playlist.onTypeChange(obj, (properties) => {
      playlist.onSetAllProperties(properties);
    });
  };
  return (
    <>
      <div className={"text-container"}>
        <p>{playlist.playlistProps.type == "playlist" && "Playlist Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-video" && "Video Scale"}</p>
        <p>{playlist.playlistProps.type == "nor-image" && "Image Scale"}</p>

        <div className={"tool--wrapper"}>
          <p>Scale Type</p>
          <div className={"text--tool"}>
            <span>Yes</span>{" "}
            <input
              type="radio"
              value={"fitXY"}
              checked={playlist?.playlistProps?.fitXY ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
            <span>No</span>{" "}
            <input
              type="radio"
              value={"none"}
              checked={playlist?.playlistProps?.none ?? false}
              onChange={onChange}
              name={"scaletype"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ToolPlaylistOp;
