import React from 'react'
import './qrcode.css';
import { QrCode } from 'lucide-react';
import SearchField from '../../../components/editor/SearchField';
function QRCode({onAddQr}) {
    
    
      function handleQRCodeSave() {
          
          // hideSidebar();
        }
        const _onAddQr=()=>{
        const qrCodeValue = document.getElementById('barcodeValue').value;
        // generateQRCode(qrCodeValue);
        onAddQr(qrCodeValue);
    }
  return (
   <>
   <div className="qr-container">
    {/* <SearchField/> */}
    <div className="tool--wrapper">
            <label htmlFor="barcodeType"
             >Barcode Type:</label>
   <div className="text--tool">
            <select id="barcodeType" 
            style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
            // className="border border-gray-300 rounded p-2 w-full"
            >
              <option>Email</option>
              <option>Phone Number</option>
              <option>Url</option>
              <option>custom</option>
            </select>
          </div>
    </div>
    <div className='tool--wrapper'>
            <label htmlFor="barcodeValue" 
            >QR Code Value:</label>
          <div className="text--tool">
            <input type="text" id="barcodeValue"
             style={{width:'100%',height:'100%',background:'white',border:'none',outline:'none'}}
              />
    </div>
          </div>
   <button
          style={{
            height: "40px",
            width: "100%",
            background:'var(--primary-color)',
            color:'var(--primary-forecolor)',
            border: "1px solid gray",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={_onAddQr}
        >
          {/* <QrCode /> */}
           Add QrCode
        </button>
   </div>
   </>
  )
}

export default QRCode
