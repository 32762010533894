import React, { useState } from "react";
import "./autosuggestion.css";
import {SearchIcon,CloseIcon, Close} from "@mui/icons-material";
import Search from "@mui/icons-material/Search";
// import CloseIcon from "@material-ui/icons";

function AutoSuggestion({ placeholder, data,onGetValue,onClear }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [isOpen,setIsOpen]=useState(false);
  const handleFilter = (event) => {
    setIsOpen(false);
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    onClear();
  };
const _onSelect=(e,obj)=>{
    setWordEntered(e);
    onGetValue(obj);
    setIsOpen(true);
}
  return (
    // <div className="search">
    <>
    {/* <div className="searchInputs"> */}
      <div className="searchIcon">
        {filteredData.length === 0 ? (
          <Search />
        ) : (
          <Close id="clearBtn" onClick={clearInput} />
        )}
      </div>
      <input
        type="text"
        placeholder={placeholder}
        value={wordEntered}
        onChange={handleFilter}
      />
    {/* </div> */}
    {filteredData.length != 0 && (
      <div className={`${isOpen ? 'data-result-off' : 'dataResult'}`}>
        {filteredData.slice(0, 15).map((value, key) => {
          if(value.name!==wordEntered){
          return (
            <a className="dataItem" onClick={()=>_onSelect(value.name,value)} target="_blank">
              <p>{value.name} </p>
            </a>
          );
      }
        })}
      </div>
    )}
    
    </>
    // </div>
  );
}

export default AutoSuggestion;