import { Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import FunctionsIcon from "@mui/icons-material/Functions";
import { Settings } from "lucide-react";
function ShapesProperty({ shapeProps }) {
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [funcAncherEl, setFuncAncherEl] = useState(null);
  const [borderAncherEl, setBorderAncherEl] = useState(null);
  const [rectRadius, setRectRadius] = useState(0);
  const [length, setLength] = useState({
    x: 10,
    y: 10,
  });
  const colorOpen = Boolean(colorAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const funcOpen = Boolean(funcAncherEl);
  const funcId = funcOpen ? "simple-popover" : undefined;
  const borderOpen = Boolean(borderAncherEl);
  const borderId = borderOpen ? "simple-popover" : undefined;
  useEffect(() => {
    console.log(shapeProps._shapeProps)
    if (shapeProps._shapeProps.shapeType == "rect") {
      setRectRadius(shapeProps._shapeProps.radius);
    }
    if(shapeProps?._shapeProps.isShadow)
    setLength({x:shapeProps?._shapeProps?.shadow?.offsetX,y:shapeProps?._shapeProps?.shadow?.offsetY})
  }, [shapeProps]);
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleFuncClick = (event) => {
    setFuncAncherEl(event.currentTarget);
  };
  const handleFuncClose = () => {
    setFuncAncherEl(null);
  };
  const handleBorderColorClick = (event) => {
    setBorderAncherEl(event.currentTarget);
  };
  const handleBorderColorClose = () => {
    setBorderAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    // setCanvaColor(color.hex);
    shapeProps.onChangeColorCompleteOfShape(color.rgb,(properties) =>
      shapeProps.onSetAllProperties(properties));
  };
  const onChangeBorderColorComplete = (color) => {
    // setBorderColor(color.hex);
    shapeProps.onChangeBorderColorCompleteOfShape(color.rgb);
  };
  const onChangeOffsetValues = (e) => {
    setLength((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const _onChangeShadowState=(e)=>{
    if(e.target.checked){
      shapeProps.onChangeShadowState(true,(properties) =>
        shapeProps.onSetAllProperties(properties));
    }else{
      shapeProps.onChangeShadowState(false,(properties) =>
        shapeProps.onSetAllProperties(properties));
    }
  }
  return (
    <div className="shape-tool-container">
      <div className="shape-tool">
        <button
          style={{
            background: `${shapeProps._shapeProps.fill}`,
            border: "1px solid black",
            height: "39px",
            width: "39px",
          }}
          aria-describedby={colorId}
          onClick={handleColorClick}
        ></button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker
            color={shapeProps._shapeProps.fill}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>
      <div className="shape-tool">
        <button
          style={{ background: "white" }}
          aria-describedby={funcId}
          onClick={handleFuncClick}
        >
          <Settings size={"30px"} />
        </button>
        <Popover
          id={funcId}
          open={funcOpen}
          anchorEl={funcAncherEl}
          onClose={handleFuncClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="shape-align-tools">
            <div className="shape-inner-align-tools">
              <span>Border Color</span>
              <button
                style={{
                  background: `${shapeProps._shapeProps.stroke}`,
                  border: "1px solid black",
                  height: "39px",
                  width: "39px",
                }}
                aria-describedby={funcId}
                onClick={handleBorderColorClick}
              ></button>
              <Popover
                id={borderId}
                open={borderOpen}
                anchorEl={funcAncherEl}
                onClose={handleBorderColorClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <SketchPicker
                  color={shapeProps._shapeProps.stroke}
                  onChangeComplete={onChangeBorderColorComplete}
                />
              </Popover>
            </div>
            {/* <div className="shape-inner-align-tools">
              <span>Thinkness</span>
              <input
                type={"number"}
                value={shapeProps._shapeProps.strokeWidth}
                minLength={1}
                maxLength={5}
                onChange={(e) =>
                  shapeProps.onChangeBorderThicknessOfShape(e.target.value)
                }
                style={{border:'1px solid gray'}}
              />
            </div> */}
            <div className="shape-inner-align-tools">
              <span>Rounded</span>
              <input
                type={"number"}
                value={rectRadius}
                min={1}
                max={50}
                onChange={(e) => {
                  setRectRadius(e.target.value);
                  shapeProps.onChangeBorderRoundedOfShape(e.target.value);
                }}
                style={{border:'1px solid gray'}}
              />
            </div>

<div className="shape-inner-align-tools">
  <p>Shadow:</p> <input type={'checkbox'} style={{height:'20px',width:'20px'}} checked={shapeProps._shapeProps.isShadow} onChange={_onChangeShadowState}/>
</div>
{
  shapeProps._shapeProps.isShadow && <>
            <div className="shape-inner-align-tools">
              <p>offsetX:</p>
              <input
                type={"number"}
                value={length.x}
                name={"x"}
                onChange={(e) => {
                  onChangeOffsetValues(e);
                  shapeProps.onChangeShapeOffsetVal({x:e.target.value,y:length.y},(properties) =>
                    shapeProps.onSetAllProperties(properties))
                }
                  
                }
                style={{border:'1px solid gray'}}
              />
            </div>

            <div className="shape-inner-align-tools">
              <p>offsetY:</p>
              <input
                type={"number"}
                value={length.y}
                name={"y"}
                onChange={(e) => {
                  onChangeOffsetValues(e);
                  shapeProps.onChangeShapeOffsetVal({y:e.target.value,x:length.x},(properties) =>
                    shapeProps.onSetAllProperties(properties))
                }
                  
                }
                style={{border:'1px solid gray'}}
              />
            </div>
  
  </>
}

          </div>
        </Popover>
      </div>
    </div>
  );
}

export default ShapesProperty;
