import React, { useEffect, useState } from "react";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import TitleIcon from "@mui/icons-material/Title";
import { Checkbox, Popover, Radio } from "@mui/material";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { setTextFontColor } from "../../../Redux/Actions/EditorAction";
function TextTools({ textProps }) {
  const { _textProps } = useSelector((state) => state.Editor);
  const dispatch = useDispatch();
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [shadowColorAncherEl, setShadowColorAncherEl] = useState(null);
  const [alignAncherEl, setAlignAncherEl] = useState(null);
  const [fontSizeAncherEl, setFontSizeAncherEl] = useState(null);
  const [fontTransparencyAncherEl, setFontTransparencyAncherEl] =
    useState(null);
  const [canvaColor, setCanvaColor] = useState("black");
  const [shadowCanvaColor, setShadowCanvaColor] = useState("black");
  const [length, setLength] = useState({
    x: 10,
    y: 10,
  });
  const colorOpen = Boolean(colorAncherEl);
  const shadowColorOpen = Boolean(shadowColorAncherEl);
  const alignOpen = Boolean(alignAncherEl);
  const fontSizeOpen = Boolean(fontSizeAncherEl);
  const fontTransparencyOpen = Boolean(fontTransparencyAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const shadowColorId = shadowColorOpen ? "simple-popover" : undefined;
  const alignId = alignOpen ? "simple-popover" : undefined;
  const fontSizeId = fontSizeOpen ? "simple-popover" : undefined;
  const fontTransparencyId = fontTransparencyOpen
    ? "simple-popover"
    : undefined;

    useEffect(()=>{
      if(textProps){
        setLength({x:textProps?.textBoxProps?.shadow?.offsetX,y:textProps?.textBoxProps?.shadow?.offsetY})
      }
    },[textProps])
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleShadowColorClick = (event) => {
    setShadowColorAncherEl(event.currentTarget);
  };
  const handleShadowColorClose = () => {
    setShadowColorAncherEl(null);
  };
  const handleAlignClick = (event) => {
    setAlignAncherEl(event.currentTarget);
  };
  const handleAlignClose = () => {
    setAlignAncherEl(null);
  };
  const handleFontSizeClick = (event) => {
    setFontSizeAncherEl(event.currentTarget);
  };
  const handleFontSizeClose = () => {
    setFontSizeAncherEl(null);
  };
  const handleFontTransparencyClick = (event) => {
    setFontTransparencyAncherEl(event.currentTarget);
  };
  const handleFontTransparencyClose = () => {
    setFontTransparencyAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextFontColor(color.rgb);
  };
  const onChangeShadowColorComplete = (color) => {
    // dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextBoxShadowColor(color.rgb);
  };
  const onChangeOffsetValues = (e) => {
    setLength((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const customAlignIcon = {
    FormatAlignCenterIconChecked: (
      <FormatAlignCenterIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
    FormatAlignLeftIconChecked: (
      <FormatAlignLeftIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
    FormatAlignRightIconChecked: (
      <FormatAlignRightIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
  };
  return (
    <div className="text-tool-container">
      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleColorClick}>
          <TextFormatIcon
            style={{
              color: `${textProps?.textBoxProps?.fill}`,
            }}
            fontSize="large"
          />
        </button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SketchPicker
            color={canvaColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>

      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleFontSizeClick}>
          <FormatSizeIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={fontSizeId}
          open={fontSizeOpen}
          anchorEl={fontSizeAncherEl}
          onClose={handleFontSizeClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <div className="text-inner-align-tools">
              <p>Font Size:</p>
              <input
                type={"range"}
                value={textProps?.textBoxProps?.fontSize}
                min={0}
                onChange={(e) => textProps.onChangeTextFontSize(e.target.value)}
              />
            </div>
            <div className="text-inner-align-tools">
              <p>Shadow:</p>
              <input
                type={"range"}
                value={textProps?.textBoxProps?.shadow?.size}
                min={0}
                onChange={(e) =>
                  textProps.onChangeTextBoxShadowBlur(e.target.value)
                }
              />
            </div>
            <div className={"text-inner-align-tools"}>
              <p>shadow Color:</p>
              <button
                className="text-tool-btn-top"
                onClick={handleShadowColorClick}
              >
                <TextFormatIcon
                  style={{
                    color: `${textProps?.textBoxProps?.shadow?.color}`,
                  }}
                  fontSize="large"
                />
              </button>
              <Popover
                id={shadowColorId}
                open={shadowColorOpen}
                anchorEl={shadowColorAncherEl}
                onClose={handleShadowColorClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <SketchPicker
                  color={shadowCanvaColor}
                  onChangeComplete={onChangeShadowColorComplete}
                />
              </Popover>
            </div>

            <div className="text-inner-align-tools">
              <p>offsetX:</p>
              <input
                type={"number"}
                value={length.x}
                name={"x"}
                onChange={(e) => {
                  onChangeOffsetValues(e);
                  textProps.onGetOffsetVal({x:e.target.value,y:length.y})
                }
                  
                }
                style={{border:'1px solid gray'}}
              />
            </div>

            <div className="text-inner-align-tools">
              <p>offsetY:</p>
              <input
                type={"number"}
                value={length.y}
                name={"y"}
                onChange={(e) => {
                  onChangeOffsetValues(e);
                  textProps.onGetOffsetVal({y:e.target.value,x:length.x})
                }
                  
                }
                style={{border:'1px solid gray'}}
              />
            </div>
          </div>
          {/* <div className="text-align-tools">
            <p>Shadow:</p>
            <input
              type={"range"}
              value={textProps?.textBoxProps?.fontSize}
              min={0}
              onChange={(e) => textProps.onChangeTextFontSize(e.target.value)}
            />
          </div> */}
        </Popover>
      </div>

      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleAlignClick}>
          <FormatAlignCenterIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={alignId}
          open={alignOpen}
          anchorEl={alignAncherEl}
          onClose={handleAlignClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <input
              type={"radio"}
              name={"align"}
              // checkedIcon={customAlignIcon["FormatAlignLeftIconChecked"]}
              // icon={<FormatAlignLeftIcon fontSize="large" />}
            />
            <input
              type={"radio"}
              name={"align"}
              // checkedIcon={customAlignIcon["FormatAlignCenterIconChecked"]}
              // icon={<FormatAlignCenterIcon fontSize="large" />}
            />
            <input
              type={"radio"}
              name={"align"}
              // checkedIcon={customAlignIcon["FormatAlignRightIconChecked"]}
              // icon={<FormatAlignRightIcon fontSize="large" />}
            />
            {/* <Checkbox checkedIcon={customAlignIcon["FormatAlignCenterIconChecked"]} icon={<FormatAlignLeftIcon fontSize='large' />}/>
             <Checkbox checkedIcon={customAlignIcon["FormatAlignLeftIconChecked"]} icon={<FormatAlignCenterIcon fontSize='large'/>}/>
             <Checkbox checkedIcon={customAlignIcon["FormatAlignRightIconChecked"]} icon={<FormatAlignRightIcon fontSize='large'/>} /> */}
          </div>
        </Popover>
      </div>
      <div className="text-tool">
        <button
          className="text-tool-btn-top"
          onClick={handleFontTransparencyClick}
        >
          <TitleIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={fontTransparencyId}
          open={fontTransparencyOpen}
          anchorEl={fontTransparencyAncherEl}
          onClose={handleFontTransparencyClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <input
              type={"range"}
              min={1}
              max={10}
              onChange={(e) =>
                textProps.onChangeTextFontTransparency(e.target.value / 10)
              }
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default TextTools;
