import { api } from "../../api/api";
import {
  GET_PLAYLIST_SUCCESS,
  ADD_CONTENT_VALUE,
  ADD_PLAYLIST_SUCCESS,
  DELETE_CONTENT_VALUE,
  ADD_PLAYLIST_REQUEST,
  DELETE_CONTENT_FROM_PLAYLIST,
  UPDATE_DURATION,
  DELETE_PLAYLIST,
  PLAYLIST_SCREEN_CHECK,
  ADD_SCREEN_TO_PLAYLIST,
  ADD_CONTENT_IN_PLAYLIST,
  GET_PLAYLIST_REQUEST,
  GET_PLAYLIST_FAILED,
  PLAYLIST_FLAG_OFF,
  PLAYLIST_SCHEDULE_SUCCESS,
  PLAYLIST_SCHEDULE_DELETE,
  CLIENT_PLAYLIST_SUCCESS,
  PLAYLIST_CONTNET_SORTING,
  PLAYLIST_TO_MULTIPLE_SCREEN,
  PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS,
  GET_FOLDER_PLAYLIST_SUCCESS,
  PLAYLIST_PERMISSION_SUCCESS,
  PLAYLIST_UPDATE_REQUEST,
  UPDATE_IMAGE_RESOLUTION,
} from "../constant";

export const playlistFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: PLAYLIST_FLAG_OFF,
      payload: "",
    });
  };
};
export const addPlaylist = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_PLAYLIST_REQUEST,
      payload: "",
    });
    const playlist = await api.post(`/playlist/add`, data);
    // console.log(playlist.data);
    if (playlist.status === 201) {
      dispatch({ type: ADD_PLAYLIST_SUCCESS, payload: playlist.data.playlist });
    }
  };
};
export const getPlaylist = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PLAYLIST_REQUEST,
      payload: "",
    });
    const playlist = await api.get(`/playlist/getplaylist/${userId}`);
    // console.log("playlist", playlist.data.playlist);
    if (playlist.status === 200) {
      if (playlist.data.playlist.length > -1) {
        const _payload = playlist.data.playlist.map((playlist) => ({
          ...playlist,
          predecessor: playlist.predecessor.map(({ user }) => user),
        }));
        dispatch({
          type: GET_PLAYLIST_SUCCESS,
          payload: _payload,
        });
      } else {
        dispatch({
          type: GET_PLAYLIST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const getPlaylistByPredecessor = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PLAYLIST_REQUEST,
      payload: "",
    });
    const { status, data } = await api.get(
      `/playlist/getplaylistbypredecessor/${id}`
    );
    if (status === 200) {
      if (data.playlists.length > -1) {
        // {
        //   ...data.updated,
        //   predecessor: data.updated.predecessor.map(({ user }) => user),
        // };
        dispatch({
          type: GET_PLAYLIST_SUCCESS,
          payload: data.playlists,
        });
      } else {
        dispatch({
          type: GET_PLAYLIST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const getPlaylistsOnFolder = (id) => {
  return async (dispatch) => {
    // dispatch({
    //   type: GET_PLAYLIST_REQUEST,
    //   payload: "",
    // });
    const playlist = await api.get(`/playlist/GetPlaylistsOnFolder/${id}`);
    // console.log("playlist", playlist.data.playlist);
    if (playlist.status === 200) {
      if (playlist.data.playlists[0]?._playlists.length > -1) {
        dispatch({
          type: GET_FOLDER_PLAYLIST_SUCCESS,
          payload: playlist.data.playlists[0]?._playlists,
        });
      } else {
        dispatch({
          type: GET_PLAYLIST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const addContentInPlaylist = (obj) => {
  return async (dispatch) => {
    // console.log(obj)
    const playlist = await api.patch(`/playlist/addcontent/${obj.id}`, {
      content: obj.value,
    });
    // console.log(playlist);
    if (playlist.status) {
      dispatch({
        type: ADD_CONTENT_IN_PLAYLIST,
        payload: playlist.data.playlist,
      });
    }
  };
};

export const addOverlayInPlaylist = (obj) => {
  return async (dispatch) => {
    // console.log(obj)
    const playlist = await api.patch(`/playlist/addoverlay/${obj.id}`, {
      content: obj.value,
    });
    // console.log(playlist);
    if (playlist.status) {
      dispatch({
        type: ADD_CONTENT_IN_PLAYLIST,
        payload: playlist.data.playlist,
      });
    }
  };
};

export const playlistScreenCheck = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: PLAYLIST_SCREEN_CHECK,
      payload: obj,
    });
  };
};
export const deleteContentFromPlaylist = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CONTENT_FROM_PLAYLIST,
      payload: obj,
    });
    const updatedPlaylist = await api.patch(
      `/playlist/deletecontentfromplaylist/${obj.playlistId}`,
      { contentId: obj.contentId }
    );
    // console.log(updatedPlaylist.data);
  };
};
export const deletePlaylist = (id) => {
  return async (dispatch) => {
    const playlist = await api.get(`/playlist/deleteplaylist/${id}`);
    if (playlist.status === 201) {
      dispatch({
        type: DELETE_PLAYLIST,
        payload: playlist.data.playlist,
      });
    }
  };
};
export const updateDuration = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DURATION,
      payload: obj,
    });
    await api.patch(`/playlist/updateduration/${obj.playlistId}`, {
      time: obj.time,
      contentId: obj.contentId,
    });
  };
};
export const updateImageResolution = (obj) => {
  return async (dispatch) => {
 const {status,data}=   await api.patch(`/playlist/updateimageresolution/${obj.playlistId}`, {
      shrink: obj.shrink,
      stretch:obj.stretch,
      x:obj.x,
      y:obj.y,
      contentId: obj.contentId,
    });
    if(status==201){
      dispatch({
        type: UPDATE_IMAGE_RESOLUTION,
        payload: data.playlist,
      });

    }
  };
};
export const updatePlaylist = (pid, cid, obj) => {
  return async (dispatch) => {
    const updated = await api.patch(`/playlist/schedule/${pid}/${cid}`, obj);
    // console.log('schdule => ',updated)
    if (updated.status === 201) {
      dispatch({
        type: PLAYLIST_SCHEDULE_SUCCESS,
        payload: updated.data.updated,
      });
    }
  };
};
export const deletePlaylistSchedule = (pid, cid) => {
  return async (dispatch) => {
    const updated = await api.patch(
      `/playlist/deletecontentschedule/${pid}/${cid}`
    );
    // console.log('deleted schdule => ',updated)
    if (updated.status === 201) {
      dispatch({
        type: PLAYLIST_SCHEDULE_DELETE,
        payload: updated.data.updated,
      });
    }
  };
};
export const getClientPlaylist = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.get(
      `/playlist/getclientplaylists/${id}`
    );
    if (status == 200) {
      dispatch({
        type: CLIENT_PLAYLIST_SUCCESS,
        payload: data.playlist,
      });
    }
  };
};
export const playlistContentSort = (obj) => {
  return async (dispatch) => {
    await api.post(`/playlist/playlistcontnetsorting`, { ...obj });
    dispatch({
      type: PLAYLIST_CONTNET_SORTING,
      payload: obj,
    });
  };
};
export const playlistToMultipleScreen = (id, list) => {
  return async (dispatch) => {
    dispatch({ type: PLAYLIST_TO_MULTIPLE_SCREEN, payload: "" });
    const { status, data } = await api.patch(
      `/playlist/assignplaylisttomultiplescreen/${id}`,
      { macList: list }
    );
    if (status == 200) {
      dispatch({
        type: PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS,
        payload: data.playlist,
      });
    }
  };
};
export const _EditPlaylist = (id, obj) => {
  return async (dispatch) => {
    dispatch({
      type:PLAYLIST_UPDATE_REQUEST,
      payload:''
    })
    const { status, data } = await api.patch(
      `/playlist/editplaylist/${id}`,
      obj
    );
    // console.log(data);
    const _payload = {
      ...data.updated,
      predecessor: data.updated.predecessor.map(({ user }) => user),
    };
    dispatch({
      type: PLAYLIST_PERMISSION_SUCCESS,
      payload: _payload,
    });
  };
};
