import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Input,
  TextField,
  FormControl,
  ListItemIcon,
  Drawer,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DonutChart from "../../components/charts/DonutChart";
import Layout from "../../components/layout/Layout";
import {
  _addShopRoutine,
  addClockWidget,
  addPlaylistToScreen,
  addWeatherWidget,
  assignMultiplePlaylistToScreen,
  screenFlagOff,
  screenResolutionAdjust,
  uncheckAssignedScreen,
} from "../../Redux/Actions/screenAction";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./setting.css";
import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
import { ReactComponent as UserProfileIcon } from "../../Assets/images/userprofileIcon.svg";
import { ReactComponent as MobileImage } from "../../Assets/images/mobileImage.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { TextareaAutosize } from "@material-ui/core";
import { playlistFlagOff } from "../../Redux/Actions/playlistAction";
import { Check, Close, DesktopMac } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import { MultiSelect } from "react-multi-select-component";
import useFilter from "../../hooks/useFilter";
import { api } from "../../api/api";
import { UPDATE_SCREEN_SUCCESS } from "../../Redux/constant";
import toast from "react-hot-toast";
import { getPacketByScreen } from "../../Redux/Actions/packetAction";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
function Setting({ user, socketRef }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { screens, isUnChecked, isScreenUpdated } = useSelector(
    (state) => state.Screen
  );
  const { playlist } = useSelector((state) => state.Playlist);
  const { packets, screen_packets } = useSelector((state) => state.Packet);
  const [searchVal, setSearchVal] = useState("");
  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [playlistInfo, setPlaylistInfo] = useState({});
  const [code, setCode] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(60);
  const [instantEvent, setInstantEvent] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openImageAdjust, setOpenImageAdjust] = useState(false);
  const [imgUrl, setImgUrl] = useState({url:'',file:''});
  const [form, setForm] = useState({
    shrink: false,
    stretch: false,
    resolutionX: 0,
    resolutionY: 0,
    offsetX: 0,
    offsetY: 0,
    isScreenResolutionOn: false,
    isScreenResolutionOff: true,
  });
  const [openHours, setOpenHours] = useState(false);
  const [days, setDays] = useState({
    mondayopen:"",
    mondayoff: "",
    tuesdayopen:"",
    tuesdayoff: "",
    wednesdayopen:"",
    wednesdayoff: "",
    thursdayopen:"",
    thursdayoff:"",
    fridayopen: "",
    fridayoff: "",
    saturdayopen:"" ,
    saturdayoff: "",
    sundayopen: "",
    sundayoff: "",
    on: false,
    off: true,
  });
  const macRef = useRef(null);
  const selRef = useRef(null);
  const filtered = useFilter(searchVal, playlists)();

  useEffect(() => {
    if (params.id) {
      dispatch(getPacketByScreen(params.id));
    }
  }, [params.id]);
  useEffect(() => {
    if (params.id) {
      let _src = screens.find((s) => s._id == params.id);
      setForm({
        shrink: _src.shrink ?? false,
        stretch: _src.stretch ?? false,
        isScreenResolutionOn: _src.isScreenResolutionOn ?? false,
        isScreenResolutionOff: _src.isScreenResolutionOff ?? true,
        resolutionX: _src.resolutionX ?? 0,
        resolutionY: _src.resolutionY ?? 0,
        offsetX: _src.offsetX ?? 0,
        offsetY: _src.offsetY ?? 0,
      });
      let routine = _src?.shoproutine ?? {};
      console.log(routine,_src)
      if (Object.entries(routine).length != 0) {
        console.log('routine',routine)
        setDays({
          mondayopen: routine.mondayopen,
          mondayoff: routine.mondayoff,
          tuesdayopen: routine.tuesdayopen,
          tuesdayoff: routine.tuesdayoff,
          wednesdayopen: routine.wednesdayopen,
          wednesdayoff: routine.wednesdayoff,
          thursdayopen: routine.thursdayopen,
          thursdayoff: routine.thursdayoff,
          fridayopen: routine.fridayopen,
          fridayoff: routine.fridayoff,
          saturdayopen: routine.saturdayopen,
          saturdayoff: routine.saturdayoff,
          sundayopen: routine.sundayopen,
          sundayoff: routine.sundayoff,
          on: routine.on,
          off: routine.off,
        });
        setImgUrl({url:routine.url,file:''});
      }
    }
    // console.log('from use Effect->',screens)
    setPlaylistInfo({
      ...screens.find((s) => s._id === params.id),
      screenAssigned: false,
    });
  }, [params.id]);
  // console.log(days);
  useEffect(() => {
    let _s=screens.find((s) => s._id === params.id)
    if (isUnChecked) {
      setPlaylistInfo({ ..._s });
      dispatch(screenFlagOff());
    }
    if (isScreenUpdated) {
      setPlaylistInfo({ ..._s });
      socketRef.current?.emit("event:delegate", {
        detail: { mac: macRef?.current?.value },
      });
      dispatch(screenFlagOff());
      toast.success("Screen updated.");
      let routine = _s?.shoproutine ?? {};
      if (Object.entries(routine).length != 0) {
        setDays({
          mondayopen: routine.mondayopen,
          mondayoff: routine.mondayoff,
          tuesdayopen: routine.tuesdayopen,
          tuesdayoff: routine.tuesdayoff,
          wednesdayopen: routine.wednesdayopen,
          wednesdayoff: routine.wednesdayoff,
          thursdayopen: routine.thursdayopen,
          thursdayoff: routine.thursdayoff,
          fridayopen: routine.fridayopen,
          fridayoff: routine.fridayoff,
          saturdayopen: routine.saturdayopen,
          saturdayoff: routine.saturdayoff,
          sundayopen: routine.sundayopen,
          sundayoff: routine.sundayoff,
          on: routine.on,
          off: routine.off,
        });
        setImgUrl({url:routine.url,file:''});
      }
      setOpenHours(false);
      setSelected([]);
      setIsScreenOpen(false);
      setOpenImageAdjust(false);
    }
  }, [isUnChecked, isScreenUpdated]);
  useEffect(() => {
    let opt = [];
    playlist.forEach((p) => {
      opt.push({ label: p.name, value: p._id });
    });
    setOptions(opt);
    socketRef.current.emit("leaving--connection");
    socketRef.current.on("screen--connection--off", (obj) => {
      //  console.log(obj)
    });
  }, []);
  const submit = () => {
    socketRef?.current.emit(
      "message",
      {
        message,
        duration,
        mac: playlistInfo.mac,
      },
      () => toast.success("Message sent.")
    );
    setOpenModel(false);
    setMessage("");
  };
  const emitAssignPlaylistToScreen = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        socketRef.current?.emit("event:delegate", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  const screenHanlderSelect = (e) => {
    setCode(e.target.value);
    const mac = screens.find((c) => c._id === params.id);
    // console.log(e.target.value);
    dispatch(
      addPlaylistToScreen({
        id: params.id,
        value: e.target.value,
        mac: mac.mac,
      })
    );
    // setPlaylistInfo({...screens.find(s=>s._id===params.id),screenAssigned:true})
    emitAssignPlaylistToScreen(params.id);
  };
  const onSearchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
  };
  const searchCallBack = useCallback(onSearchFilter, [searchVal]);

  const onGetScreenAssign = useCallback((e, _s) => {
    const { value, checked, name } = e.target;

    if (checked) {
      setPlaylists((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: true, disable: false } : s
        ),
      ]);

      setSelected((prev) => [...prev, value]);
    } else {
      setPlaylists((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: false, disable: false } : s
        ),
      ]);

      setSelected((prev) => prev.filter((f) => f != value));
    }

    // }
  }, []);

  const onAssignPlaylist = async () => {
    try {
      // let mac_list = [];
      // for (let i in filtered) {
      //   if (filtered[i].isChecked) {
      //     mac_list.push(filtered[i].id);
      //   }
      // }
      dispatch(assignMultiplePlaylistToScreen(params.id, selected));
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSelectPlaylist = useCallback(() => {
    let pl = [];
    let playlistObject = {};
    if (Object.keys(playlistObject).length == 0) {
      screens
        .find((s) => s._id == params.id)
        ._playlist.forEach(({ playlist }) => {
          if (Object.hasOwnProperty(playlist?._id)) {
          } else {
            setSelected((prev) => [...prev, playlist?._id]);
            playlistObject[playlist?._id] = playlist?.name;
          }
        });
      console.log(playlistObject);
      playlist.forEach((p) => {
        if (playlistObject[p._id] == p.name) {
          pl.push({ id: p._id, name: p.name, isChecked: true });
        } else {
          pl.push({ id: p._id, name: p.name, isChecked: false });
        }
      });

      setPlaylists(pl);
    }
    setIsScreenOpen(true);
  }, [params.id]);
  // console.log(playlists);
  const onGetRadio = (e) => {
    const { name, id } = e.target;
    if (id == "shrink") {
      setForm((prev) => ({ ...prev, [id]: true, stretch: false }));
    }
    if (id == "stretch") {
      setForm((prev) => ({ ...prev, [id]: true, shrink: false }));
    }
    if (id == "isScreenResolutionOff") {
      setForm((prev) => ({ ...prev, [id]: true, isScreenResolutionOn: false }));
    }
    if (id == "isScreenResolutionOn") {
      setForm((prev) => ({
        ...prev,
        [id]: true,
        isScreenResolutionOff: false,
      }));
    }
  };

  const onGetDaysValue = (e) => {
    const { name, value } = e.target;
    setDays((prev) => ({ ...prev, [name]: value }));
  };
  const onGetRadioValue = (e) => {
    const { id } = e.target;
    if (id == "dayson") {
      setDays((prev) => ({ ...prev, on: true, off: false }));
    }
    if (id == "daysoff") {
      setDays((prev) => ({ ...prev, on: false, off: true }));
    }
  };
  // console.log(screens)
  return (
    <>
      <input hidden ref={macRef} value={playlistInfo?.mac} />
      <input hidden ref={selRef} value={selected.length} />
      <Layout title={"Screen Details"} user={user}>
        <Grid container>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <div className="device flex flex-column">
                <div className="device-header flex flex-content-space-around alignItemCenter">
                  <div className="device-header-left flex">
                    <div className="screen-icon flex justifyContentCenter alignItemCenter">
                      <MobileFriendlyIcon
                        style={{ color: "var(--primary-color)" }}
                      />
                      {/* <img src={"/images/MobileScreen.png"} /> */}
                    </div>
                    <div className="screen-text flex flex-column">
                      <p>{playlistInfo?.device_details?.device_name}</p>
                      {playlistInfo?.onlinesStatus ? (
                        <span>Connected</span>
                      ) : (
                        <span>Disconnected</span>
                      )}
                    </div>
                  </div>
                  {/* <div style={{}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {playlistInfo.isClock ? (
                        <span>
                          <Check /> Clock
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Close />
                          Clock
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {playlistInfo.isWeather ? (
                        <span>
                          <Check /> Weather
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Close /> Weather
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="device-header-right flex">
                    <div className="playlist-selector flex flex-column justifyContentCenter alignItemCenter">
                      <span className="screen-text">
                        Select playlist to show on the screen
                      </span>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>
                          {playlistInfo?._playlist?.map((_, _index) => (
                            <span>{_?.playlist?.name} , </span>
                          ))}
                        </p>
                        <BorderColorIcon
                          onClick={onSelectPlaylist}
                          style={{ color: "var(--primary-color)" }}
                          // onClick={() =>
                          //   dispatch(uncheckAssignedScreen(playlistInfo._id))
                          // }
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item lg={8}>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Code</p>
                            <span>{playlistInfo.code}</span>
                          </div>
                          <div className="info-2">
                            <p>Device Name</p>
                            <span>
                              {playlistInfo?.device_details?.device_name}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Name</p>
                            <span>{playlistInfo?.name}</span>
                          </div>
                          <div className="info-2">
                            <p>Manufacturing</p>
                            <span>
                              {playlistInfo?.device_details?.manufacture}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>OS</p>
                            <span>
                              {playlistInfo?.device_details?.os_version}
                            </span>
                          </div>
                          <div className="info-2">
                            <p>Location</p>
                            <span>United State</span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Mac Address</p>
                            <span>{playlistInfo.mac}</span>
                          </div>
                          <div className="info-2">
                            <p>Ip Address</p>
                            <span>{playlistInfo?.device_details?.ip}</span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Software</p>
                            <span>
                              {playlistInfo?.device_details?.software}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Root Level</p>
                            <span>
                              {playlistInfo?.device_details?.root_level}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p></p>
                            <div style={{ margin: "10px 0" }}>
                              <button
                                onClick={() => setOpenDrawer(true)}
                                style={{
                                  background: "var(--primary-color)",
                                  color: "white",
                                  width: "150px",
                                  height: "30px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                              >
                                Remote controll
                              </button>
                              <button
                                onClick={() => setOpenImageAdjust(true)}
                                style={{
                                  background: "var(--primary-color)",
                                  color: "white",
                                  width: "150px",
                                  height: "30px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                              >
                                Image Adjust
                              </button>
                              <button
                                onClick={() => {
                                  setOpenHours(true)
                               
                                }}
                                style={{
                                  background: "var(--primary-color)",
                                  color: "white",
                                  width: "150px",
                                  height: "30px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                              >
                                Open Hours
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#15192C",
                        fontSize: "18px",
                        py: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Device Details
                      </Typography>
                    </Box>
                    <Box>
                      <div className="ram">
                        <DonutChart
                          views={"Ram"}
                          series={[
                            +playlistInfo?.ram?.total?.split(" ")[0] -
                              +playlistInfo?.ram?.free?.split(" ")[0],
                            +playlistInfo?.ram?.free?.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${playlistInfo?.ram?.total?.split(" ")[1]}`,
                            `Free ${playlistInfo?.ram?.free?.split(" ")[1]}`,
                          ]}
                        />
                      </div>
                    </Box>
                    <Box>
                      <div className="storage">
                        <DonutChart
                          views={"Storage"}
                          series={[
                            +playlistInfo?.storage?.total?.split(" ")[0] -
                              +playlistInfo?.storage?.free?.split(" ")[0],
                            +playlistInfo?.storage?.free?.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${
                              playlistInfo?.storage?.total?.split(" ")[1]
                            }`,
                            `Free ${
                              playlistInfo?.storage?.free?.split(" ")[1]
                            }`,
                          ]}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // background: "var(--primary-color)",
                background: "white",
                padding: "15px 20px",
                color: "white",
                margin: "15px",
                borderRadius: "15px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Action
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={instantEvent}
                  onChange={(e) => setInstantEvent(e.target.value)}
                  variant={"outlined"}
                  label="Select Action"
                  sx={{
                    border: "1px solid white",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <MenuItem
                    value={"Close App"}
                    onClick={() =>
                      socketRef.current.emit("close--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Close App
                  </MenuItem>
                  <MenuItem
                    value={"aunch App"}
                    onClick={() =>
                      socketRef.current.emit("launch--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Lauch App
                  </MenuItem>
                  <MenuItem
                    value={"Reset App"}
                    onClick={() =>
                      socketRef.current.emit("reset--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Reset App
                  </MenuItem>
                  <MenuItem
                    value={"Reload App"}
                    onClick={() =>
                      socketRef.current.emit("reload--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Reload App
                  </MenuItem>
                  <MenuItem
                    value={"Refresh App"}
                    onClick={() =>
                      socketRef.current.emit("refresh--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Refresh App
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <UserProfileIcon />
              <Typography
                sx={{
                  fontSize: "12px",
                  pl: 1,
                }}
              >
                User profile settings
              </Typography> */}
            </Box>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <MobileImage />
              <Box>
                <Typography
                  sx={{
                    color: "#15192C",
                    fontSize: "16px",
                    fontWeight: "bold",
                    mt: 3,
                  }}
                >
                  Instant Messaging
                </Typography>
                <Typography
                  sx={{
                    color: "#92959E",
                    fontSize: "15px",
                  }}
                >
                  Display real-time notifications on screens for a set duration,
                  effectively communicating important updates to the audience
                </Typography>
                <Button
                  variant={"outlined"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 20px",
                    color: "var(--primary-color)",
                    margin: "15px",
                    borderRadius: "6px",
                    border: " 1px solid var(--primary-color)",
                    mt: 3,
                    fontSize: "15px",
                    pl: 1,
                    width: {
                      xl: "300px",
                      lg: "300px",
                      md: "300px",
                      sm: "200px",
                      xs: "200px",
                    },
                    textTransform: "capitalize",
                  }}
                  onClick={() => setOpenModel(true)}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Layout>

      <Dialog
        open={openHours}
        onClose={() => {
          setOpenHours(false)
        setImgUrl('');
        }}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>Opening Hours</DialogTitle>
        <DialogContent>
          <div
            style={{
              minHeight: "70dvh",
              width: "62dvw",
              border: "1px solid white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <div
              style={{
                minHeight: "60vh",
                width: "70%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "1px solid white",
              }}
            >
              <div
                style={{
                  height: "3vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gap: "100px",
                  // border: "1px solid black",
                }}
              >
                <span>Open</span>
                <span>Closed</span>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Mon</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"time"}
                    name={"mondayopen"}
                    value={days.mondayopen}
                    onChange={onGetDaysValue}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"time"}
                    name={"mondayoff"}
                    value={days.mondayoff}
                    onChange={onGetDaysValue}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Tue</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"time"}
                    name={"tuesdayopen"}
                    value={days.tuesdayopen}
                    onChange={onGetDaysValue}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"tuesdayoff"}
                    value={days.tuesdayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Wed</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"wednesdayopen"}
                    value={days.wednesdayopen}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"wednesdayoff"}
                    value={days.wednesdayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Thu</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"thursdayopen"}
                    value={days.thursdayopen}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"thursdayoff"}
                    value={days.thursdayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Fri</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"fridayopen"}
                    value={days.fridayopen}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"fridayoff"}
                    value={days.fridayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Sat</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"saturdayopen"}
                    value={days.saturdayopen}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"saturdayoff"}
                    value={days.saturdayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <span>Sun</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"sundayopen"}
                    value={days.sundayopen}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
                </div>
                <span>:</span>
                <div
                  style={{
                    height: "100%",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                 
                  <input
                    type={"time"}
                    onChange={onGetDaysValue}
                    name={"sundayoff"}
                    value={days.sundayoff}
                    style={{
                      height: "70%",
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  />
     
                </div>
              </div>
              <div
                style={{
                  height: "7vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  // border: "1px solid black",
                }}
              >
                <button
                  style={{ background: "var(--primary-color)", color: "white",height:'40px',width:'150px',borderRadius:'10px' }}
                  onClick={() => {
                    const formData = new FormData();
                    if(imgUrl.file==""){
                      days.url=imgUrl.url;
                    }
                    formData.append("days", JSON.stringify(days));
                    formData.append("content", imgUrl.file);
                    dispatch(_addShopRoutine(params.id, formData));
                    // api
                    //   .patch(`/screen/addShopRoutine/${params.id}`, formData)
                    //   .then((res) => console.log(res))
                    //   .catch((err) => console.log(err));
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              style={{
                minHeight: "70vh",
                width: "30%",
                // border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  height: "10vh",
                  width: "100%",
                  // border: "1px solid black",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span>On</span>{" "}
                <input
                  type="radio"
                  id="dayson"
                  checked={days.on}
                  onChange={onGetRadioValue}
                  style={{ height: "20px", width: "20px" }}
                  name="state"
                />
                <span>Off</span>{" "}
                <input
                  type="radio"
                  id="daysoff"
                  checked={days.off}
                  onChange={onGetRadioValue}
                  style={{ height: "20px", width: "20px" }}
                  name="state"
                />
              </div>
              <div
                style={{
                  height: "60vh",
                  width: "100%",
                  // border: "1px solid black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <input
                  type="file"
                  id="file-hours"
                  hidden
                  onChange={(e) => {
                    const _img = e.target.files[0];
                    setImgUrl({url:URL.createObjectURL(_img),file:_img});
                  
                  }}
                />
                <label
                  style={{
                    background: "var(--primary-color)",
                    color: "white",
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    height: "40px",
                    width: "150px",
                    borderRadius: "10px",
                  }}
                  htmlFor="file-hours"
                >
                  Select Img
                </label>
                <div
                  style={{
                    height: "70%",
                    width: "70%",
                    border: "1px solid gray",
                    borderRadius:'10px'
                  }}
                  id="img"
                >
                  <img src={imgUrl.url} style={{height:'100%',width:'100%',objectFit:'contain'}}/>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openImageAdjust}
        onClose={() => setOpenImageAdjust(false)}
        maxWidth={"lg"}
        sx={{
          zIndex: "1000000000",
        }}
      >
        <DialogTitle>Image Resolution Properties</DialogTitle>
        <DialogContent>
          <Box sx={{ minHeight: "40vh", width: "40vw" }}>
            <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  height: "10%",
                  width: "100%",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <input
                  type={"radio"}
                  id={"isScreenResolutionOff"}
                  name={"rVal"}
                  checked={form.isScreenResolutionOff}
                  onChange={onGetRadio}
                  style={{ height: "20px", width: "20px" }}
                />{" "}
                OFF
                <input
                  type={"radio"}
                  id={"isScreenResolutionOn"}
                  name={"rVal"}
                  checked={form.isScreenResolutionOn}
                  onChange={onGetRadio}
                  style={{ height: "20px", width: "20px" }}
                />{" "}
                ON
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ textAlign: "center" }}>Resolution px</span>
                <TextField
                  required
                  variant="standard"
                  name={"resolutionX"}
                  value={form.resolutionX}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="X Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  name={"resolutionY"}
                  value={form.resolutionY}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="Y Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />

                <div
                  style={{
                    height: "10%",
                    width: "100%",
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"radio"}
                    id={"stretch"}
                    name={"radioVal"}
                    checked={form.stretch}
                    onChange={onGetRadio}
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  Scale
                  <input
                    type={"radio"}
                    id={"shrink"}
                    name={"radioVal"}
                    checked={form.shrink}
                    onChange={onGetRadio}
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  None
                </div>
                <span style={{ textAlign: "center" }}>Offset px</span>
                <TextField
                  required
                  variant="standard"
                  name={"offsetX"}
                  value={form.offsetX}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="X Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  name={"offsetY"}
                  value={form.offsetY}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="Y Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />

                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={() =>
                    dispatch(screenResolutionAdjust(params.id, form))
                  }
                  // onClick={onUpdateImageResolution}
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isScreenOpen}
        maxWidth={"sm"}
        onClose={() => {
          setSelected([]);
          setIsScreenOpen(false);
        }}
        sx={{
          zIndex: "100000000000",
        }}
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          <span>Assign to screens</span>{" "}
          <Close
            onClick={() => {
              setSelected([]);
              setIsScreenOpen(false);
            }}
            sx={{ color: "red", cursor: "pointer" }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2px'}}>
            <input type='checkbox' onChange={onAddClock} style={{height: "20px", width: "20px"}}/> Clock
            </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2px'}}>
            <input type={'checkbox'} onChange={onAddWeather} style={{height: "20px", width: "20px"}} /> Weather
            </div> */}
            {/* <select
           onChange={onGetFolderScreen} 
          style={{height:'100%',width:'100%'}}>
               {
                playlist?.map((_,_index)=>(<option key={_index} value={_?._id}>{_?.name}</option>))
               }
            </select> */}
          </Box>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={searchVal}
              onChange={searchCallBack}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            ></Box>
            {filtered?.map((_, _index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    name={_?.name}
                    value={_?.id}
                    onChange={(e) => {
                      onGetScreenAssign(e, selected);
                    }}
                    checked={_.isChecked}
                    disabled={_.disable}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>{_?.name}</span>
                </Box>
              </>
            ))}
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                onAssignPlaylist();
              }}
              variant={"contained"}
              sx={{
                background: "var(--primary-color)",
                textTransform: "capitalize",
              }}
              className="multiple--assign--btn"
            >
              Assign
            </Button>
            {/* {isPlaylistAssign && <MiniLoader />} */}
            {/* <span>Total Screens {screens.length}</span> */}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"xl"}
        fullWidth={true}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Send Message
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              {/* <AddScreenIcon /> */}
              <MailOutlineIcon
                sx={{
                  color: "var(--primary-color)",
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                type={"text"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant={"outlined"}
                label={"Write Message"}
                multiline
                fullWidth
              />
            </div>

            <div className="form-fields">
              <TextField
                type={"number"}
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                sx={{
                  width: "100px",
                }}
              />
            </div>

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "var(--primary-color)",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={submit}
                className="btn primary"
              >
                Send
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
      >
        <div
          style={{
            width: "30vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Remote Button</h2>
          </div>

          <div
            style={{
              minHeight: "40vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {screen_packets?.map((packet) => (
              <button
                onClick={() => {
                  if (socketRef.current) {
                    socketRef.current?.emit(
                      "packet",
                      {
                        ip: packet?.ip,
                        udp: packet?.udp,
                        tcp: packet?.tcp,
                        hexcode: packet?.hexcode,
                        mac: playlistInfo?.mac,
                      },
                      () => toast.success("Packet sent.")
                    );
                  }
                }}
                style={{
                  height: "70px",
                  minWidth: "150px",
                  background: "var(--primary-color)",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "5px",
                  // margin:'0 10px'
                }}
              >
                {packet.name}
              </button>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Setting;
