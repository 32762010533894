import React, { useEffect } from "react";
import "./picture.css";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import SearchField from "../../../components/editor/SearchField";
export default function Picture({ onGetImage,onLoadImageFromDb }) {
  const { content, isContentLoading } = useSelector((state) => state.Content);
  // useEffect(() => {
  //   fetch(
  //     "https://api.unsplash.com/photos?client_id=e411zzD4bAdrWICpDPTai9k3tS2rFIUEtVuAx0GBdYc"
  //   ).then((res) => console.log(res));
  // }, []);
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    dir.click();
  };
  return (
    <>
    <div className="picture-component-container">
      {/* <SearchField/> */}
      <input
        id={"file-get"}
        type={"file"}
        onChange={(e) => onGetImage(e.target.files[0])}
        hidden
      />
      {/* <button 
      style={{
        height: "40px",
        width: "100%",
        background:'var(--primary-color)',
        color:'var(--primary-forecolor)',
        border: "1px solid gray",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
           onClick={openDirectory}
           >Upload Image</button> */}
      <div className="upslash-container">
        {
          content.map((con,index)=>{
            if(con.type=='image'){
              return (
                <div className='upslash-divs' onClick={()=>onLoadImageFromDb(con.url)}>
                  <img src={con.url}  />
                  <span>{con?.name?.substring(0,20)}</span>
                </div>
              )
            }
          })
        }
        
      </div>
    </div>
    
    </>
  );
}