import { api } from "../../api/api";
import {
  DELETE_SCREEN,
  SCREEN_STATUS_DEACTIVE,
  SCREEN_STATUS_ACTIVE,
  ADD_SCREEN_REQUEST,
  ADD_SCREEN_SUCCESS,
  SCREEN_REQUEST,
  SCREEN_REQUEST_SUCCESS,
  SCREEN_REQUEST_FAILED,
  ADD_SCREEN_TO_PLAYLIST,
  ADD_PLAYLIST_TO_SCREEN,
  UNCHECK_ASSIGNED_SCREEN,
  SCREEN_FLAG_OFF,
  FIND_ONE_SCREEN,
  CLIENT_SCREENS_REQUEST,
  CLIENT_SCREENS_SUCCESS,
  UPDATE_SCREEN,
  UPDATE_SCREEN_SUCCESS,
  MOVE_SCREEN_TO_FOLDER_REQUEST,
  MOVE_SCREEN_TO_FOLDER_SUCCESS,
  SCREEN_FILTER,
  OPEN_CALENDER,
  CLOSE_CALENDER,
} from "../constant";

export const addScreen = (data, code) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const updated = await api.patch(`/screen/update/${code}`, data);
    // console.log(updated);
    dispatch({ type: ADD_SCREEN_SUCCESS, payload: updated.data.screen });
  };
};

export const updateScreen = (_data, id) => {
  return async (dispatch) => {
    // console.log(_data, id);
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(`/screen/editscreen/${id}`, _data);
    // console.log(data);
    if (status == 200)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.updatedScreen });
  };
};
export const screenResolutionAdjust = (id, obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(
      `/screen/screenresolutionadjust/${id}`,
       obj 
    );
    // console.log(data.screen)
    if (status == 201) {
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
    }
  };
};

export const _addShopRoutine = (id, obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(
      `/screen/addShopRoutine/${id}`,
       obj 
    );
    // console.log(data.screen)
    if (status == 201) {
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.routine });
    }
  };
};

export const getScreens = (id) => {
  return async (dispatch) => {
    dispatch({ type: SCREEN_REQUEST, payload: "" });
    const screens = await api.get(`/screen/getscreens/${id}`);
    // console.log(screens)
    if (screens.status === 200) {
      if (screens.data.screens.length > -1) {
        dispatch({
          type: SCREEN_REQUEST_SUCCESS,
          payload: screens.data.screens,
        });
      } else {
        dispatch({
          type: SCREEN_REQUEST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const getScreensBySuperadmin = () => {
  return async (dispatch) => {
    dispatch({ type: SCREEN_REQUEST, payload: "" });
    const screens = await api.get(`/screen/getScreensBySuperadmin`);
    // console.log(screens)
    if (screens.status === 200) {
      if (screens.data.screens.length > -1) {
        dispatch({
          type: SCREEN_REQUEST_SUCCESS,
          payload: screens.data.screens,
        });
      } else {
        dispatch({
          type: SCREEN_REQUEST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const getScreensOnFolder = (id) => {
  return async (dispatch) => {
    dispatch({ type: SCREEN_REQUEST, payload: "" });
    const screens = await api.get(`/screen/GetScreensOnFolder/${id}`);
    // console.log(screens)
    if (screens.status === 200) {
      if (screens.data.screens.length !== 0) {
        // console.log("inside true");
        // console.log(screens.data.screens[0]._screens)
        dispatch({
          type: SCREEN_REQUEST_SUCCESS,
          payload: screens.data.screens
          // payload: screens.data.screens[0]?._screens,
        });
      } else {
        dispatch({
          type: SCREEN_REQUEST_FAILED,
          payload: "",
        });
      }
    }
  };
};

export const screenStatusActive = (mac, status) => {
  return async (dispatch) => {
    const updated = await api.patch(
      `/screen/screenstatusupdate/${encodeURIComponent(mac)}`,
      { status }
    );
    if (updated.status === 201) {
      dispatch({
        type: SCREEN_STATUS_ACTIVE,
        payload: updated.data.updated,
      });
    }
  };
};
export const screenStatusDeActive = (mac, status) => {
  return async (dispatch) => {
    const updated = await api.patch(
      `/screen/screenstatusupdate/${encodeURIComponent(mac)}`,
      { status }
    );
    if (updated.status === 201) {
      dispatch({
        type: SCREEN_STATUS_DEACTIVE,
        payload: updated.data.updated,
      });
    }
  };
};
export const deleteScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SCREEN,
      payload: id,
    });
    await api.get(`/screen/deletescreen/${id}`);
  };
};
export const addPlaylistToScreen = (obj) => {
  return async (dispatch) => {
    const screen = await api.patch(`/screen/addplaylisttoscreen/${obj.id}`, {
      playlistId: obj.value,
      mac: obj.mac,
    });
    // console.log('updated playlist assigning=',screen);
    if (screen.status === 201) {
      dispatch({
        type: ADD_PLAYLIST_TO_SCREEN,
        payload: screen.data.screen,
      });
    }
  };
};
export const uncheckAssignedScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: UNCHECK_ASSIGNED_SCREEN,
      payload: id,
    });
  };
};
export const screenFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: SCREEN_FLAG_OFF,
      payload: "",
    });
  };
};
export const findOneScreen = (id) => {
  return async (dispatch) => {
    dispatch({
      type: FIND_ONE_SCREEN,
      payload: id,
    });
  };
};
export const getClientScreens = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CLIENT_SCREENS_REQUEST,
      payload: "",
    });

    const { status, data } = await api.get(`/screen/getclientscreens/${id}`);
    if (status === 200) {
      dispatch({
        type: CLIENT_SCREENS_SUCCESS,
        payload: data.screens,
      });
    }
  };
};

export const moveScreenToFolder = (screenId, folderId) => {
  return async (dispatch) => {
    dispatch({ type: MOVE_SCREEN_TO_FOLDER_REQUEST, payload: "" });
    const { status, data } = await api.patch("/screen/MoveScreenToFolder", {
      screenId,
      folderId,
    });
    if (status == 201) {
      dispatch({ type: MOVE_SCREEN_TO_FOLDER_SUCCESS, payload: data.screen });
    }
  };
};

export const assignMultiplePlaylistToScreen = (id, idList) => {
  return async (dispatch) => {
    const { status, data } = await api.patch(
      `/screen/AddMultipleplaylistToScreen/${id}`,
      { playlistId: idList }
    );

    // const _screen = {
    //   ...data.screen,
    //   _playlist: data.screen._playlist.map(({ playlist }) => playlist),
    // };
    if (status == 201)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};
export const licensUpdate = (id) => {
  return async (dispatch) => {
    // console.log(_data, id);
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(`/screen/screenupdated/${id}`);
    // const _screen = {
    //   ...data.screen,
    //   _playlist: data.screen._playlist.map(({ playlist }) => playlist),
    // };
    if (status == 201)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};

export const licenseReUpdate = (id, license) => {
  return async (dispatch) => {
    console.log(id, license);
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(`/screen/licenseupdated/${id}`, {
      license,
    });
    // const _screen = {
    //   ...data.screen,
    //   _playlist: data.screen._playlist.map(({ playlist }) => playlist),
    // };
    if (status == 201)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};
export const licenseDateUpdate = (id, date) => {
  return async (dispatch) => {
    // console.log( id,license);
    dispatch({
      type: ADD_SCREEN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.patch(
      `/screen/licensedateupdated/${id}`,
      { date }
    );
    // const _screen = {
    //   ...data.screen,
    //   _playlist: data.screen._playlist.map(({ playlist }) => playlist),
    // };
    // console.log(_screen);
    if (status == 201)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};
export const addClockWidget = (id, clock) => {
  return async (dispatch) => {
    const { status, data } = await api.patch(`/screen/AddClockWidget/${id}`, {
      clock,
    });

    if (status == 200)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};

export const addWeatherWidget = (id, weather) => {
  return async (dispatch) => {
    const { status, data } = await api.patch(`/screen/AddWeatherWidget/${id}`, {
      weather,
    });

    if (status == 200)
      dispatch({ type: UPDATE_SCREEN_SUCCESS, payload: data.screen });
  };
};
export const screenFilters = (state) => {
  return async (dispatch) => {
    dispatch({
      type: SCREEN_FILTER,
      payload: state,
    });
  };
};
export const openCalender = (id) => {
  return async (dispatch) => {
    dispatch({
      type: OPEN_CALENDER,
      payload: id,
    });
  };
};
export const closeCalender = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE_CALENDER,
      payload: id,
    });
  };
};
