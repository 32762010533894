import React from "react";
import "./video.css";
// import videoPlayerThumbnail from "../../video-player-thumbnail.png";
import video_icon from '../../../Assets/images/video-icon.png';
import { useSelector } from "react-redux";
import SearchField from "../../../components/editor/SearchField";
function Video({ onGetVideo, onLoadVideoFromDb }) {
  const { _content, isContentLoading } = useSelector((state) => state.Content);
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    // dir.click();
  };
  return (
    <div className="video-component-container">
      {/* <SearchField/> */}
      <input
        id={"file-get"}
        type={"file"}
        onChange={(e) => onGetVideo(e.target.files[0])}
        hidden
      />
      {/* <button
         style={{
          height: "40px",
          width: "100%",
          background:'var(--primary-color)',
          color:'var(--primary-forecolor)',
          border: "1px solid gray",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
        onClick={openDirectory}
      >
        Upload Video
      </button> */}
      <div className="usplash-container">
        {_content.map((con, index) => {
          if (con.type == "video") {
            console.log(con)
            return (
              <div
                className="upslash-divs"
                onClick={() => onLoadVideoFromDb({url:con?.url,thumbnail:con?.thumbnail})}
              >
                <img src={con?.thumbnail} />
                <span>{con?.name?.substring(0, 20)}</span>
              </div>
            );
          }
        })}
      
      </div>
    </div>
  );
}

export default Video;
